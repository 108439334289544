import React from 'react';
import { Create, EditProps, required, SimpleForm, Toolbar } from 'react-admin';
import StyledGridLayout from '../../components/StyledGridLayout';
import { StyledSelectInput, StyledTextInput } from '../../components/StyledInputs';
import SaveButtonAware from '../../components/SaveButtonAware';

const BusinessCreateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButtonAware />
  </Toolbar>
);

const BusinessCreate = (props: EditProps) => (
  <Create {...props} title="Businesses">
    <SimpleForm toolbar={<BusinessCreateToolbar />} redirect="show">
      <StyledGridLayout>
        <StyledTextInput source="name" validate={[required()]} />
        <StyledSelectInput
          source="type"
          label="Type"
          choices={[
            { id: 'Reseller', name: 'Reseller' },
            { id: 'BundlePartner', name: 'Bundle Partner' },
            { id: 'Insurer', name: 'Insurer' },
          ]}
          validate={[required()]}
        />
      </StyledGridLayout>
    </SimpleForm>
  </Create>
);

export default BusinessCreate;
