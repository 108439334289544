import { gql } from '@apollo/client';
import {
  RaFetchType,
  RaGetResponse,
  RaListParams,
  RaListResponse,
  RaQuery,
  RaUpdateParams,
} from '../../interfaces/RaQueries';
import { OfferType } from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';
import getOffersListParams from '../../utils/getOffersListParams';
import getOfferUpdateParams from '../../utils/getOfferUpdateParams';

interface ListResult {
  offers: {
    offers: Array<OfferType>;
    overallCount: number;
  };
}

export enum OfferActions {
  attachOfferToBundle = 'ATTACH_OFFER_TO_BUNDLE',
  detachOfferFromBundle = 'DETACH_OFFER_FROM_BUNDLE',
}

const Offers = (raFetchType: RaFetchType, params: any): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query ($limit: Int, $offset: Int, $searchPhrase: String, $order: OfferQueryOrderEnum!) {
            offers(
              params: { limit: $limit, offset: $offset, searchPhrase: $searchPhrase, order: $order }
            ) {
              offers {
                id
                offerName
                offerType
                contentfulEntityId
                offerDetailSlug
                createdAt
                heroContent {
                  id
                  title
                  content
                  offerImage {
                    id
                    url
                  }
                  logoImage {
                    id
                    url
                  }
                  offerAction {
                    title
                    link
                  }
                }
                secondaryContent {
                  id
                  title
                  content
                  offerImage {
                    id
                    url
                  }
                  logoImage {
                    id
                    url
                  }
                  offerAction {
                    title
                    link
                  }
                  cardColor
                }
                tileContent {
                  id
                  title
                  content
                  offerImage {
                    id
                    url
                  }
                  logoImage {
                    id
                    url
                  }
                  offerAction {
                    title
                    link
                  }
                }
              }
              overallCount
            }
          }
        `,
        variables: getOffersListParams(params as RaListParams),
        parseResponse: ({ data }: ApiResponse<ListResult>): RaListResponse<OfferType> => ({
          data: data.offers.offers.map((item) => item),
          total: data.offers.overallCount,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($id: String!) {
            offer(id: $id) {
              id
              offerName
              offerType
              contentfulEntityId
              offerDetailSlug
              createdAt
              heroContent {
                id
                title
                content
                offerImage {
                  id
                  url
                }
                logoImage {
                  id
                  url
                }
                offerAction {
                  title
                  link
                }
              }
              secondaryContent {
                id
                title
                content
                offerImage {
                  id
                  url
                }
                logoImage {
                  id
                  url
                }
                offerAction {
                  title
                  link
                }
                cardColor
              }
              tileContent {
                id
                title
                content
                offerImage {
                  id
                  url
                }
                logoImage {
                  id
                  url
                }
                offerAction {
                  title
                  link
                }
              }
              attachedBundles {
                rank
                offerType
                bundle {
                  id
                  name
                  internalName
                }
              }
            }
          }
        `,
        variables: { id: params.id },
        parseResponse: ({ data }): RaGetResponse<OfferType> => ({
          data: {
            ...data.offer,
          },
        }),
      };
    case 'CREATE':
      return {
        query: gql`
          mutation ($input: CreateOfferInputType!) {
            createOffer(input: $input) {
              id
              offerName
              offerType
              contentfulEntityId
              offerDetailSlug
              createdAt
            }
          }
        `,
        variables: { input: params.data },
        parseResponse: ({ data }): RaGetResponse<OfferType> => ({
          data: data.createOffer,
        }),
      };
    case 'UPDATE':
      if (params.data.action === OfferActions.attachOfferToBundle) {
        return {
          query: gql`
            mutation ($input: AttachOfferToBundleInputType!) {
              attachOfferToBundle(input: $input)
            }
          `,
          variables: {
            input: {
              bundleId: params.data.bundleId,
              offerId: params.id,
              rank: 1,
              bundleOfferType: params.data.bundleOfferType,
            },
          },
          parseResponse: (): any => ({
            data: params.data,
          }),
        };
      }

      if (params.data.action === OfferActions.detachOfferFromBundle) {
        return {
          query: gql`
            mutation ($input: DetachOfferFromBundleInputType!) {
              detachOfferFromBundle(input: $input)
            }
          `,
          variables: {
            input: {
              bundleId: params.data.bundleId,
              offerId: params.id,
            },
          },
          parseResponse: (): any => ({
            data: params.data,
          }),
        };
      }
      // default UPDATE action
      return {
        query: gql`
          mutation ($id: String!, $input: UpdateOfferInputType!) {
            updateOffer(id: $id, input: $input) {
              id
              offerName
              offerType
              contentfulEntityId
              offerDetailSlug
              createdAt
              heroContent {
                id
                title
                content
                offerImage {
                  id
                  url
                }
                logoImage {
                  id
                  url
                }
                offerAction {
                  title
                  link
                }
              }
              secondaryContent {
                id
                title
                content
                offerImage {
                  id
                  url
                }
                logoImage {
                  id
                  url
                }
                offerAction {
                  title
                  link
                }
                cardColor
              }
              tileContent {
                id
                title
                content
                offerImage {
                  id
                  url
                }
                logoImage {
                  id
                  url
                }
                offerAction {
                  title
                  link
                }
              }
            }
          }
        `,
        variables: getOfferUpdateParams(params as RaUpdateParams<OfferType>),
        parseResponse: ({ data }): RaGetResponse<OfferType> => ({
          data: data.updateOffer,
        }),
      };

    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default Offers;
